import "./vendor/modernizr-3.11.2.min.js"
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import SplitType from 'split-type';
import barba from '@barba/core';

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

// Media query 64em ><
let mm = gsap.matchMedia();
//Horizontal Container animation tween
let tweenScroll;

// Notes for Development 
/************************************************

1. Loading Screen
2. Page Animation
3. Vertical Scroll Triggers - Mobile < 64em
	3.1 Link Scroll To Section
	3.2 Fade In X Content
	3.3 Marquee Scroll Horizontal

4. Horizontal Scroll Triggers - Desktop > 64em
	4.1 Link Scroll To Section
	4.2 Set up Horizontal width and scroll container
	4.3 Update width on refresh
	4.4 Apply ScrollTrigger to Width/Container
	4.5 Parallax Landing Page
	4.6 Image Blocks
		4.6.1 Slide Image Blocks Up
		4.6.2 Slide Image Blocks Down
	4.7 Marquee Scroll Vertical
	4.8 Text Reveal - Titles Scroll Trigger
	4.9 Draw Line - About Section
	5.0 Fade In Y Content
	5.1 Welcome Words Fade
	5.2 Plus Button Class Toggle

5. Global Navigation Animation
6. Global Text/Video Animation on Load
	6.1 Reveal Title 1 Timeline
	6.1 Reveal Title 2 Timeline
	6.1 Reveal Video Timeline

-------- Reorganise -------
7. Split Type 
8. Cursor
9. Delay Function - Pause Loading
10. Video - Hover/ Play + Pause
11. Number tally animation
12. Mobile Touch/Hover Functions init

--------- Page Tranistions ----------

13. Barba Init
	13.1 Kill and Refresh - Research
	13.2 Init - Research

**************************************************/


/************************************************
// 			1. Loading - Animation
**************************************************/


function loadingScreen() {
	
	let counter = {
		value: 0
	};

	let loaderDuration = 2;

	if (sessionStorage.getItem("visited") !== null) {
		loaderDuration = 1;
		counter = {
			value: 65
		};
	}

   sessionStorage.setItem("visited", "true");

	function loaderText() {
		let progress = Math.round(counter.value);
		document.querySelector(".loader--number span").textContent = progress;
	}

	let loadtl = gsap.timeline({ onComplete:()=> {animateOnLoad(); animateOnLoadPara(); } });

	loadtl.to(counter, {
		onUpdate: loaderText,
		value: 100,
		duration: loaderDuration
	}).to(".loader-dots", {
		opacity: 0,
		duration: 0.3
	}).to(".loading .velvet--badger", {
		opacity: 0,
		duration: 0.3
	}).to(".loading", {
		duration: 1.2,
		x: "-110%",
		ease: "Expo.easeInOut"
	});
}


/************************************************
// 			2. Page Animation
**************************************************/

// Page transition
function pageTransitionSlide() {
    let tl = gsap.timeline({onComplete:()=> {animateOnLoad(); animateOnLoadPara(); } });
    tl.to('.loading--screen', {
        duration: 1.2,
        x: "-10%",
        ease: "Expo.easeInOut"
    });
    tl.to('.loading--screen', {
        duration: 1,
        x: "120%",
        ease: "Expo.easeInOut",
        delay: 0.3
    });
    tl.set('.loading--screen', {x: "-110%"});
}


/************************************************
// 	3. Vertical Scroll Triggers - Mobile > 64em
**************************************************/


// Vertical < 64em (Mobile effects)
function verticalScroll() {

	
	// 3.1 Link Scroll To Section
	document.querySelectorAll(".waypoint").forEach(anchor => {
		anchor.addEventListener("click", function(e) {
			e.preventDefault();
			let targetElem = document.querySelector(e.target.getAttribute("href")),
				y = targetElem;
			//gsap.to(window, {duration: 1, scrollTo:{y:"#section" + (index + 1), offsetY:70}});
			gsap.to(window, {
				scrollTo: {
					y: y,
					autoKill: false
				},
				duration: 1
			});
		});
	});


	// 3.2 Fade In X Content
	const fadeIn = gsap.utils.toArray('.fadeIn');
	fadeIn.forEach((fade, i) => {
	const animFade = gsap.fromTo(fade, {autoAlpha: 0, y: 50}, {duration: 1, autoAlpha: 1, y: 0});
		ScrollTrigger.create({
			trigger: fade,
			animation: animFade,
			toggleActions: 'play none none none',
			once: true,
		});
	});

	// 3.3 Marquee Scroll Horizontal
	const scrollPanels = gsap.utils.toArray('.marquee ul');
	scrollPanels.forEach((scroll, i) => {
		const animMarq = gsap.fromTo(scroll, {x: 0}, {x: -200});
		ScrollTrigger.create({
			trigger: scroll,
			animation: animMarq,
			toggleActions: 'play none none none',
			scrub: 1
		});
	});

/* OLD - KEEPING FOR REFERENCE */
	// Bulevar Uppercase Heading reveal
	const textReveal = gsap.utils.toArray('.text--reveal');
	textReveal.forEach((title, i) =>{
			
		const chars = title.querySelectorAll('.char');
		wrapElements(chars, 'span', 'char-wrap');
		
		const animText = gsap.fromTo(chars, {'will-change': 'transform', transformOrigin: '0% 50%', xPercent: 105, visibility:"visible", opacity:0}, {delay: 0.5, duration: 1, ease: 'expo', xPercent: 0, opacity: 1, stagger: 0.042});

		ScrollTrigger.create({
			trigger: title,
			animation: animText,
			toggleActions: 'play none none none',
			once: true
		});
	});

	const parallaxFaking = gsap.utils.toArray('.parallax--fake');
	if (parallaxFaking.length > 0) {
		parallaxFaking.forEach(parallax => {
			gsap.to(parallax, { 
				y: 200,
				ease: "none",
				scrollTrigger: {
					trigger: parallax,
					containerAnimation: tweenScroll,
					start: "bottom 100%",
					end: "bottom 0",
					scrub: true,
					toggleActions: "play none reverse none"
				}
			})
		});
	}


}

/************************************************
// 	NEW. Waypoints
**************************************************/

function waypoints() {
	document.querySelectorAll(".home--section").forEach(home => {
		home.addEventListener("click", function(e) {
			e.preventDefault();
			gsap.to(window, {
				scrollTo: {
					y: 0,
					autoKill: false
				},
				duration: 1
			});
		});
	});
	document.querySelectorAll(".services--section").forEach(service => {
		service.addEventListener("click", function(e) {
			e.preventDefault();
			gsap.to(window, {
				scrollTo: {
					y: 2945,
					autoKill: false
				},
				duration: 1
			});
		});
	});
	// Projects
	document.querySelectorAll(".projects--section").forEach(project => {
		project.addEventListener("click", function(e) {
			e.preventDefault();
			gsap.to(window, {
				scrollTo: {
					y: 5955,
					autoKill: false
				},
				duration: 1
			});
		});
	});
	// About
	document.querySelectorAll(".about--section").forEach(about => {
		about.addEventListener("click", function(e) {
			e.preventDefault();
			gsap.to(window, {
				scrollTo: {
					y: 7870,
					autoKill: false
				},
				duration: 1
			});
		});
	});
	// Network
	document.querySelectorAll(".network--section").forEach(network => {
		network.addEventListener("click", function(e) {
			e.preventDefault();
			gsap.to(window, {
				scrollTo: {
					y: 14250,
					autoKill: false
				},
				duration: 1
			});
		});
	});
	// Contact
	document.querySelectorAll(".contact--section").forEach(contact => {
		contact.addEventListener("click", function(e) {
			e.preventDefault();
			gsap.to(window, {
				scrollTo: {
					y: 17500,
					autoKill: false
				},
				duration: 1
			});
		});
	});
}


/************************************************
// 	4. Horizontal Scroll Triggers - Desktop > 64em
**************************************************/

// Horizontal Scrolling  
function horizontalSections() {

	/* - Old - to be reinstated */
	let sectionsContainer = document.querySelector(".container"), tweenScroll;


	/*
	document.querySelectorAll(".anchor").forEach(anchor => {
		anchor.addEventListener("click", function(e) {
			e.preventDefault();
			let targetElem = document.querySelector(e.target.getAttribute("href")),
				y = targetElem;
			if (targetElem && sectionsContainer.isSameNode(targetElem.parentElement)) {
				let totalScroll = tweenScroll.scrollTrigger.end - tweenScroll.scrollTrigger.start,
					totalMovement = (sections.length - 1) * targetElem.offsetWidth;
				y = Math.round(tweenScroll.scrollTrigger.start + (targetElem.offsetLeft / totalMovement) * totalScroll);
			}
			gsap.to(window, {
				scrollTo: {
					y: y,
					autoKill: false
				},
				duration: 1
			});
		});
	});
	*/

	// 4.2 Set up Horizontal width and scroll container
	const sections = gsap.utils.toArray("section");
	let maxWidth = 0;
	const getMaxWidth = () => {
		maxWidth = 0;
		sections.forEach((section) => {
			maxWidth += section.offsetWidth;
		});
	};
	getMaxWidth();

	// 4.3 Update width on refresh
	ScrollTrigger.addEventListener("refreshInit", getMaxWidth);
	
	// 4.4 Apply ScrollTrigger to Width/Container
	tweenScroll = gsap.to(sections, {
		x: () => `-${maxWidth - window.innerWidth}`,
		ease: "none",
		scrollTrigger: {
			trigger: ".container",
			pin: true,
			start: "top top",
			scrub: 1,
			end: () => `+=${maxWidth}`,
			invalidateOnRefresh: true
		}
	});
	
	// Apply scroll position to center on Hash Target
	let getPosition = getScrollLookup("section", {start: "center center", containerAnimation: tweenScroll});

	gsap.utils.toArray("a.waypoint").forEach(el => {
	el.addEventListener("click", e => {
			e.preventDefault();
			gsap.to(window, {
				scrollTo: getPosition(el.getAttribute("href")),
				overwrite: "auto",
				duration: 1
			});
		});
	});

	function getScrollLookup(targets, {start, pinnedContainer, containerAnimation}) {
		let triggers = gsap.utils.toArray(targets).map(el => ScrollTrigger.create({
			  trigger: el,
			  start: start || "top top",
			  pinnedContainer: pinnedContainer,
			  refreshPriority: -10,
			  containerAnimation: containerAnimation
			})),
			st = containerAnimation && containerAnimation.scrollTrigger;
		return target => {
		  let t = gsap.utils.toArray(target)[0],
			  i = triggers.length;
		  while (i-- && triggers[i].trigger !== t) {};
		  if (i < 0) {
			return console.warn("target not found", target);
		  } 
		  return containerAnimation ? st.start + (triggers[i].start / containerAnimation.duration()) * (st.end - st.start) : triggers[i].start;
		};
	  }

  // Scroll to the element in the URL's hash on load
  //	scrollToHash(window.location.hash);



	// 4.5 Parallax Landing Page
	const itemShift = gsap.utils.toArray('.item--shift');
	if (itemShift.length > 0) {
		function parallax(event) {
			this.querySelectorAll(".item--shift").forEach((shift) => {
				const position = shift.getAttribute("value");
				const x = (window.innerWidth - event.pageX * position) / 90;
				//const y = (window.innerHeight - event.pageY * position) / 90;
	
				//shift.style.transform = `translateX(${x}px) translateY(${y}px)`;
				shift.style.transform = `translateX(${x}px)`;
			});
		}
		document.addEventListener("mousemove", parallax);
	}
	

	// 4.6 Image Blocks
	//	4.6.1 Slide Image Blocks Up
	//gsap.set(".slide--up", {y: 0})
	const panelSlide1 = gsap.utils.toArray('.slide--up');
	if (panelSlide1.length > 0) {
		gsap.set(".slide--up", {y: 60})
		panelSlide1.forEach(slideUP => {
			gsap.to(slideUP, { 
				y: -120,
				ease: "none",
				scrollTrigger: {
					trigger: slideUP,
					containerAnimation: tweenScroll,
					start: "bottom 100%",
					end: "bottom 0",
					scrub: true,
					toggleActions: "play none none reset",
					id: "111"
				}
			})
		});
	}


	//	4.6.2 Slide Image Blocks Down
	//
	const panelSlide2 = gsap.utils.toArray('.slide--down');
	if (panelSlide1.length > 0) {
		panelSlide2.forEach(slideDOWN => {
			gsap.set(".slide--down", {y: -120})
			gsap.to(slideDOWN, { 
				y: 60,
				ease: "none",
				scrollTrigger: {
					trigger: slideDOWN,
					containerAnimation: tweenScroll,
					start: "bottom 100%",
					end: "bottom 0",
					scrub: true,
					toggleActions: "play none none reset",
					id: "111"
				}
			})
		});
	}

	const parallaxFaking = gsap.utils.toArray('.parallax--fake');
	if (parallaxFaking.length > 0) {
		parallaxFaking.forEach(parallax => {
			gsap.to(parallax, { 
				x: 400,
				ease: "none",
				scrollTrigger: {
					trigger: parallax,
					containerAnimation: tweenScroll,
					start: "bottom 100%",
					end: "bottom 0",
					scrub: true,
					toggleActions: "play none reverse none"
				}
			})
		});
	}

	
	const circularText = gsap.utils.toArray('.circular--text__copy');
	if (circularText.length > 0) {
		circularText.forEach(textcircle => {
			gsap.set(textcircle, {rotate: -360})
			gsap.to(textcircle, { 
				rotate: 0,
				ease: "none",
				scrollTrigger: {
					trigger: textcircle,
					containerAnimation: tweenScroll,
					start: "bottom 110%",
					end: "bottom 0",
					scrub: true,
					toggleActions: "play none reverse none"
				}
			})
		});
	}

	
	const blockQuotes = gsap.utils.toArray('.blockquote');
	if (blockQuotes.length > 0) {
		blockQuotes.forEach(blockQuote => {
			ScrollTrigger.create({
				trigger: blockQuote,
				containerAnimation: tweenScroll,
				onEnter() {
					blockQuote.classList.add('active');
				  },
				start: "center 70%"
			 });
		});
	}


	

	// 4.7 Marquee Scroll Vertical
	//gsap.set(".marquee ul", {y: 0})
	const scrollPanels = gsap.utils.toArray('.marquee ul');
	if (scrollPanels.length > 0) {
		gsap.set(".marquee ul", {y: 0})
		scrollPanels.forEach((scroll, i) => {
			gsap.to(scroll, { 
				y: 400,
				ease: "none",
				scrollTrigger: {
					trigger: scroll,
					containerAnimation: tweenScroll,
					start: "center 150%",
					end: "center 10%",
					scrub: true,
					toggleActions: "play none none reset",
					id: "88"
				}
			})
		});
	}


		
	const ticker = document.querySelectorAll(".ticker--banner");

	if (ticker.length > 0) {
		
		const speed = 50;

		let target = document.querySelector(".ticker--banner");
		let original_html = target.innerHTML;
		let new_html = "<div class='ticker-items'>" + original_html + "</div>";
		target.innerHTML = new_html;
		target.innerHTML += new_html;
		
		let tickerWidth = document.querySelector(".ticker-items").offsetWidth;
		let initDuration = tickerWidth / speed;
		
		const tl = gsap.timeline( {repeat: -1} );
		
		tl.to(".ticker-items", {
			duration: initDuration,
			xPercent: -100,
			ease: "none",
			repeat: -1
		});

		target.addEventListener('mouseenter',()=>{
			tl.pause();
		});
		target.addEventListener('mouseleave',()=>{
			tl.play();
		});

	}


		/************************************************
		// 	7. Split Type 
		**************************************************/


	const VB = new SplitType('.split', { types: 'words, chars' });
	const wrapElements = (elems, wrapType, wrapClass) => {
		elems.forEach(char => {
			const wrapEl = document.createElement(wrapType);
			wrapEl.classList = wrapClass;
			char.parentNode.appendChild(wrapEl);
			wrapEl.appendChild(char);
		});
	}
	
	// 4.8 Text Reveal - Titles Scroll Trigger
	const textReveal = gsap.utils.toArray('.text--reveal');
	if (textReveal.length > 0) {
		textReveal.forEach((text, i) => {
			const character = text.querySelectorAll('.char');
				wrapElements(character, 'span', 'char-wrap');
				const animTextIn = gsap.fromTo(character, {'will-change': 'transform', transformOrigin: '0% 50%', xPercent: 105, opacity: 0, visibility:"visible"}, {delay: 0.5, duration: 1, ease: 'expo', xPercent: 0, opacity:1, stagger: 0.042});
				ScrollTrigger.create({
					trigger: text,
					animation: animTextIn,
					containerAnimation: tweenScroll,
					toggleActions: 'play none reverse none',
					start: "center 70%",
					end: "center 70%"
				});
		});
	}
	
	// 4.9 Draw Line - About Section
	const drawLine = gsap.utils.toArray('.line');
	if (drawLine.length > 0) {
		drawLine.forEach((line, i) => {
		const animLine = gsap.from(line, {scaleX: 0, duration: 1});
			ScrollTrigger.create({
				trigger: line,
				animation: animLine,
				containerAnimation: tweenScroll,
				toggleActions: 'play none reverse none',
				start: "center 50%",
				end: "center 50%"
			});
		});
	}


	// 5.0 Fade In Y Content
	const fadeInY = gsap.utils.toArray('.fadeInY');
	if (fadeInY.length > 0) {
		fadeInY.forEach((fade, i) => {
		const animFade = gsap.fromTo(fade, {autoAlpha: 0, x: 50}, {duration: 0.7, autoAlpha: 1, x: 0});
			ScrollTrigger.create({
				trigger: fade,
				animation: animFade,
				containerAnimation: tweenScroll,
				toggleActions: 'play none reverse none',
				start: "center 70%",
				end: "center 70%"
			});
		});
	}
	// 5.0 Fade In X Content
	const fadeInX = gsap.utils.toArray('.fadeInX');
	if (fadeInX.length > 0) {
		fadeInX.forEach((fade, i) => {
		const animFade = gsap.fromTo(fade, {autoAlpha: 0, y: 50}, {duration: 0.3, autoAlpha: 1, y: 0});
			ScrollTrigger.create({
				trigger: fade,
				animation: animFade,
				containerAnimation: tweenScroll,
				toggleActions: 'play none reverse none',
				start: "bottom 40%",
				end: "bottom 40%"
			});
		});
	}

	const words = gsap.utils.toArray('.section--reveal .word');
	if (words.length > 0) {
		gsap.from(words, {
			duration: 0.5,
			opacity:0,
			stagger: 0.15,
			ease: "none",
			scrollTrigger: {
				trigger: words,
				containerAnimation: tweenScroll,
				start: "left 80%"
			}
		});
	}

	const listItems = gsap.utils.toArray('.service--detail ul li');
	if (listItems.length > 0) {
		gsap.from(listItems, {
			duration: 0.5,
			opacity:0,
			stagger: 0.3,
			ease: "power1.out",
			scrollTrigger: {
				trigger: listItems,
				containerAnimation: tweenScroll,
				start: "left 60%"
			}
		});
	}


	const revealList = gsap.utils.toArray('.navigation--large ul li');
	if (revealList.length > 0) {
		gsap.from(revealList, {
			duration: 0.5,
			opacity:0,
			x: 50,
			stagger: 0.3,
			ease: "ease-in",
			scrollTrigger: {
				trigger: revealList,
				containerAnimation: tweenScroll,
				start: "left 60%"
			}
		});
	}
	// 5.2 Plus Button Class Toggle
	//New
	

	const plusBtns = document.querySelectorAll('.content--trigger__active .icon--plus__btn');

		plusBtns.forEach(function(plusBtn) {

			plusBtn.addEventListener('click', function() {

				plusBtn.classList.toggle('active');
				this.parentElement.classList.toggle("active");
				this.parentElement.querySelector('.content--block__reveal').classList.toggle("active");	

			});
		});

	/* Font Fix issue for Marquees - Ugly fix to be reimplemented */
		
		const marquees = document.querySelectorAll('.marquee');
		let os = "type--fix__ref";

		if (navigator.appVersion.indexOf("Win") != -1) os = "type--fix";
		
		Array.prototype.forEach.call(marquees, function (marquee) {
			marquee.classList.add(os)
		});


}


/************************************************
// 	5. Global Navigation Animation
**************************************************/


var tl = gsap.timeline({ paused: true });

//let loadtl = gsap.timeline({onComplete: animateOnLoad});

function openNav() {
	animateOpenNav();

	const navBtn = document.getElementById("menu--toggle__btn");

	navBtn.onclick = function (e) {
		// Toggle reversed to it's opposite value
		tl.reversed(!tl.reversed());
		// Use the toggle method in the classList API
		document.body.classList.toggle("navigation--open");
	};
}

function closeNav() {
	animateOpenNav();

	const navLink = document.querySelectorAll(".menu--link__item");
	navLink.forEach((navItem, i) => {
		navItem.onclick = function (e) {
			// Toggle reversed to it's opposite value
			tl.reversed(!tl.reversed({onComplete: animateOnLoad}));
			// Use the toggle method in the classList API
			document.body.classList.toggle("navigation--open");
		};
	});
	
}


function animateOpenNav() {
  const Nav = document.getElementById("header--navigation");
	tl.to(Nav, {
		duration: 0.8,
		ease: "Power2.easeOut",
		y: 0
	}).to(".animate--down__in", {
		opacity: 1,
		y: 0,
		duration: 0.5,
		delay: 0,
		stagger: {
			// wrap advanced options in an object
			each: 0.1,
			ease: "Power2.easeOut"
		}
	}).to(".contact--details", {
		opacity: 1,
		y: 0,
		duration: 0.5,
		delay: 0.5,
		ease: "Power2.easeOut"
	}, 0);
	tl.reverse();
}


/************************************************
// 	6. Global Text/Video Animation on Load
**************************************************/

function animateOnLoad() {


	const textReveal1 = gsap.utils.toArray('.text--reveal__load1');
	const textReveal2 = gsap.utils.toArray('.text--reveal__load2');
	// 6.1 Reveal Title 1 Timeline
	textReveal1.forEach((title, i) => {
		const chars = title.querySelectorAll('.char');
		wrapElements(chars, 'span', 'char-wrap');	
		const animText = gsap.fromTo(chars, {'will-change': 'transform', transformOrigin: '0% 50%', xPercent: 105, visibility:"visible", opacity: 0}, {delay: 0, duration: 1, ease: 'expo', xPercent: 0, opacity:1, stagger: 0.042});
		ScrollTrigger.create({
			trigger: title,
			animation: animText,
			toggleActions: 'play none none none',
			once: true
		});
	});
	// 6.2 Reveal Title 2 Timeline
	textReveal2.forEach((title, i) => {
		const chars = title.querySelectorAll('.char');
		wrapElements(chars, 'span', 'char-wrap');
		const animText = gsap.fromTo(chars, {'will-change': 'transform', transformOrigin: '0% 50%', xPercent: 105, visibility:"visible", opacity: 0}, {delay: 1, duration: 1, ease: 'expo', xPercent: 0, opacity:1, stagger: 0.042});
		ScrollTrigger.create({
			trigger: title,
			animation: animText,
			toggleActions: 'play none none none',
			once: true
		});
	});
	// 6.3 Reveal Video Timeline
	/*
	const videoReveal = gsap.utils.toArray('.revealVideo');
	gsap.set(videoReveal, {clipPath: 'polygon(55% 0, 55% 0, 45% 100%, 45% 100%'});
	gsap.to(videoReveal, {
		clipPath: 'polygon(4% 0%, 100% 0%, 96% 100%, 0% 100%',
		duration: 1,
		delay: 0.5
	});
	*/
}

function animateOnLoadPara() {
	const textReveal3 = gsap.utils.toArray('.text--reveal__load3');
	textReveal3.forEach((title, i) => {		
		const animText = gsap.fromTo('.text--reveal__load3', {opacity: 0}, {delay: 1, duration: 1.5, ease: 'easeinout', opacity:1, visibility: 'visible', stagger: 0.5});
		ScrollTrigger.create({
			trigger: '.text--reveal__load3',
			animation: animText,
			toggleActions: 'play none none none',
			once: true
		});
	});
}

/************************************************
// 	7. Split Type 
**************************************************/


const VB = new SplitType('.split', { types: 'words, chars' });
const wrapElements = (elems, wrapType, wrapClass) => {
	elems.forEach(char => {
		const wrapEl = document.createElement(wrapType);
		wrapEl.classList = wrapClass;
		char.parentNode.appendChild(wrapEl);
		wrapEl.appendChild(char);
	});
}

/************************************************
// 	8. Cursor
**************************************************/ 

function cursorAnimate() {
    gsap.set(".cursor--ball", {xPercent: -50, yPercent: -50});

    const ball = document.querySelector(".cursor--ball");
	const hoverables = document.querySelectorAll('.view--page');
	const iframes = document.querySelectorAll("iframe, .iframe--load");
    const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
    const mouse = { x: pos.x, y: pos.y };
    const speed = 0.2;
    
    const xSet = gsap.quickSetter(ball, "x", "px");
    const ySet = gsap.quickSetter(ball, "y", "px");

    window.addEventListener("mousemove", onMouseMove => {    
        mouse.x = onMouseMove.x;
        mouse.y = onMouseMove.y; 
		for (let i = 0; i < hoverables.length; i++) {
			hoverables[i].addEventListener('mouseenter', onMouseHover);
			hoverables[i].addEventListener('mouseleave', onMouseHoverOut);
		}
		for (let i = 0; i < iframes.length; i++) {
			iframes[i].addEventListener('mouseenter', onMouseHoveriFrame);
			iframes[i].addEventListener('mouseleave', onMouseHoverOutiFrame);
		} 
    });
    gsap.ticker.add(() => {
        const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio()); 
        pos.x += (mouse.x - pos.x) * dt;
        pos.y += (mouse.y - pos.y) * dt;
        xSet(pos.x);
        ySet(pos.y);
    });
	function onMouseHover() {
		gsap.set(ball, { className: "cursor--ball view--item"}, "+=1");
		tweenScroll = gsap.to(ball, {
			duration: .2,
			scale: 3
		})
	}
	function onMouseHoverOut() {
		gsap.set(ball, { className: "cursor--ball"}, "+=1");
		tweenScroll = gsap.to(ball, {
			duration: .2,
			scale: 1
		})
	}
	function onMouseHoveriFrame() {
		tweenScroll = gsap.to(ball, {
			duration: .2,
			scale: 0
		})
	}
	function onMouseHoverOutiFrame() {
		tweenScroll = gsap.to(ball, {
			duration: .2,
			scale: 1
		})
	}
}

/************************************************
// 	9. Cookie
**************************************************/

function cookieWarning() {
    const cookieBox = document.querySelector(".cookie--consent"),
    buttons = document.querySelectorAll(".button");

    const executeCodes = () => {
      //if cookie contains codinglab it will be returned and below of this code will not run
      if (document.cookie.includes("velvetBadger")) return;
      cookieBox.classList.add("show");

      buttons.forEach((button) => {
        button.addEventListener("click", () => {
          cookieBox.classList.remove("show");
          //if button has acceptBtn id
          if (button.id == "accept--btn") {
            document.cookie = "cookieBy= velvetBadger; max-age=" + 60 * 60 * 24 * 30;
          }
        });
      });
    };
    window.addEventListener("load", executeCodes);
  }

  cookieWarning();


/************************************************
// 	9. Delay Function - Pause Loading
*************************************************/

function delay(n) {
    n = n || 2000;
    return new Promise((done) => {
        setTimeout(() => {
            done();
        }, n);
    });
}

/************************************************
// 	10. Video - Hover/ Play + Pause
**************************************************/
/*
function videoMouseOverOut() {
	let clip = document.querySelector(".video--embed")
	clip.addEventListener("mouseover", function (e) {
		clip.play();
	})
	clip.addEventListener("mouseout", function (e) {
		clip.pause();
	})
}
*/

function playPauseVideo() {
    const videos = document.querySelectorAll("video");
	if (videos.length > 0) {
		videos.forEach((video) => {
			// We can only control playback without insteraction if video is mute
			video.muted = true;
			// Play is a promise so we need to check we have it
			const playPromise = video.play();
			if (playPromise !== undefined) {
				playPromise.then((_) => {
					const observer = new IntersectionObserver(
						(entries) => {
							entries.forEach((entry) => {
								if (
									entry.intersectionRatio !== 1 &&
									!video.paused
								) {
									video.pause();
								} else if (video.paused) {
									video.play();
								}
							});
						},
						{ threshold: 0.5 }
					);
					observer.observe(video);
				}).catch(error => {

				});
			}
		});
	}
}


/************************************************
// 	11. iFrame
**************************************************/

function iframeLoad() {
	const iframeLoad = document.querySelectorAll( ".iframe--load" );
  	for (var i = 0; i < iframeLoad.length; i++) {
    
	//const source = document.querySelector( ".iframeimg" );

   // const source = "https://img.youtube.com/vi/"+ iframeLoad[i].dataset.embed +"/sddefault.jpg";
   /* 
    var image = new Image();
        image.src = source;
        image.addEventListener( "load", function() {
			iframeLoad[ i ].appendChild( image );
        }( i ) );
    */
        iframeLoad[i].addEventListener( "click", function() {

          var iframe = document.createElement( "iframe" );

              iframe.setAttribute( "frameborder", "0" );
              iframe.setAttribute( "allowfullscreen", "" );
			  iframe.setAttribute( "allow", "camera; microphone" );
			  iframe.setAttribute("class", "iframe--type1 project--hero__asset");
              iframe.setAttribute( "src", "https://share.yoonity.co.uk/"+ this.dataset.embed );

              this.innerHTML = "";
              this.appendChild( iframe );
        } );  
  };
}


/************************************************
// 	11. Number tally animation
**************************************************/
/*

function numberAnimation() {
	gsap.from(".number", {
		innerText: 0,
		duration: 3,
		stagger: 0.3,
		snap : {
			innerText: 0.1
		}
	});
}
numberAnimation();
*/

/************************************************
// 12. Mobile Touch/Hover Functions init
**************************************************/

function is_touch_enabled() {
	return ( 'ontouchstart' in window ) ||
		   ( navigator.maxTouchPoints > 0 ) ||
		   ( navigator.msMaxTouchPoints > 0 );
}
if( is_touch_enabled() ) {
	
} else {
	cursorAnimate();
}


/************************************************
// 13. Barba Init - Transitions
**************************************************/

/////////// Function init ////////////////

function cleanGSAP() {
	ScrollTrigger.getAll().forEach(t => t.kill(false));
	ScrollTrigger.refresh(console.log("cleaned"));
	window.dispatchEvent(new Event("resize"));
}

openNav();
playPauseVideo();
iframeLoad();

mm.add("(min-width: 64rem)", () => {
	horizontalSections();
	//waypoints();
	

});
// Mobile Functions
mm.add("(max-width: 63.9rem)", () => {
	verticalScroll();
});

/*
gtag('config', 'G-HXPC7Z1615', {
	send_page_view: false,
  });
*/

/////////// BARBA + Function init ////////////////
barba.hooks.beforeLeave(() => {
});
barba.hooks.enter(() => {
   window.scrollTo(0, 0);
});
barba.hooks.afterLeave(() => {
});
barba.hooks.after(() => {
    cleanGSAP();
	iframeLoad();
	mm.add("(min-width: 64rem)", () => {
		horizontalSections();
		//waypoints();
		cursorAnimate();

	});
	mm.add("(max-width: 63.9rem)", () => {
		verticalScroll();
		
	});
	
	gtag('event', 'page_view', {
		'page_title': document.title,
		'page_location': location.href,
		'page_path': location.pathname,
	});
	
});

barba.init({
	debug: true,
	transitions: [{
	  name: 'default-transition',
		async leave(data) {
			const done = this.async();
			pageTransitionSlide();
			await delay(1000);
			done();
		},
		async enter(data) {
			//cursorAnimate();
			cookieWarning();
			playPauseVideo();
		},
		async once(data) {
			loadingScreen();
			closeNav();
		}
	  }, {
		custom: ({ trigger }) => {
			return trigger.classList && trigger.classList.contains('menu--link__item');
		},
		async leave(data) {
			const done = this.async();
			closeNav();
			await delay(1000);
			done();
		},
		async enter(data) {
			//cursorAnimate();
			cookieWarning();
			playPauseVideo();
			loadingScreen();
		}
	  }]
});